import '../index.css';

function About() {
  return (
    <div>
      <p className='text-gray-0'>About</p>
    </div>
  );
}

export default About;
