import '../index.css';

function Documentation() {
  return (
    <div>
      <p className='text-gray-0'>Documentation</p>
    </div>
  );
}

export default Documentation;
